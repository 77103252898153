import React, { useState,useEffect } from "react";
import { updateBookmark } from "api/dynamoApi";
import {  useSelector } from 'react-redux';
import { getAPIContent } from "api/acousticApi";
import { getUrlCountryPath } from 'utils/urlUtils';
import './Bookmark.scss';
import { BookmarkFilled, BookmarkOutlined } from "components/shared/SvgIcons";

// Component that renders the 'KK Orange Logo' along with the 'Veeva approval code' (string) above the references block.
const Bookmark = ({
  contentId,
  template,
  basePageDescription,
  bookmarkSaved,
  bookmarkLabel
}) => {

  const { data } = useSelector((state) => state.authentication);
  const { user } = data || {};
  const userId = user.userId;
  const { token } = data?.token;

  const [isBookmarked, setIsBookmarked] = useState(false);
    // Load bookmark status from local storage on mount
  useEffect(() => {
    // Attempt to get 'bookmarkIds' from local storage, default to an empty array if it doesn't exist
    const bookmarkedIds = JSON.parse(localStorage.getItem('bookmarkIds')) || [];
    if (bookmarkedIds.includes(contentId)) {
      setIsBookmarked(true);
    }
  }, [contentId]);
  
  const getContents = async (contentId) => {
    try {
      const contents = await getAPIContent(contentId);
      return {
        name: contents?.elements?.title?.value,
        url: contents?.elements?.urlSlug?.value,
        description: contents?.elements?.bookmarkDescription?.value,
      };
    } catch (error) {
      console.error("Error fetching content:", error);
      return null; // Return null if an error occurs
    }
  };

  const handleBookmark = async () => {
    const bookmarkedIds = JSON.parse(localStorage.getItem('bookmarkIds')) || [];
    
    const details = await getContents(contentId);
    const today = new Date();
    if (!isBookmarked) {
      const body = {
        pageUrl: `${getUrlCountryPath()}${details?.url}`,
        pageTitle: details?.name,
        pageId: contentId,
        userId: userId,
        provider: "moodle",
        type: "bookmark",
        id: null,
        inactive: false,
        template:template,
        data: {
          externalId:contentId,
          name: details?.name,
          url: details?.url
        },
      eventId: null,
      pageDescription:basePageDescription
      }
      try {
        // Make the API call to register the bookmark
        const response = await updateBookmark({ body,token,userId });

        if (response.error) {
          throw new Error('Failed to save bookmark');
        }

        console.log("Bookmark saved successfully");
          // Retrieve current bookmarks or initialize an empty array if 'bookmarkIds' doesn't exist
        const bookmarkedIds = JSON.parse(localStorage.getItem('bookmarkIds')) || [];
        bookmarkedIds.push(contentId);
        localStorage.setItem('bookmarkIds', JSON.stringify(bookmarkedIds));
        setIsBookmarked(true);
      } catch (error) {
        console.error('Error saving bookmark:', error);
        setIsBookmarked(false);
      }
    }
  };

  return (
    <button 
    className="page_bookmark"
      onClick={handleBookmark} 
      style={{
        background: "none",
        border: "none",
        cursor: isBookmarked ? "default" : "pointer",
        padding: "0",
      }}
      disabled={isBookmarked} // Disable button once bookmarked
      aria-label="Bookmark"
    >
      {isBookmarked?<><BookmarkFilled/><span className="bookmark_text">{`${bookmarkSaved||"Saved"}`}</span></> :<><BookmarkOutlined/><span className="bookmark_text">{`${bookmarkLabel||"Bookmark"}`}</span></> }
    </button>
  );
};


export default Bookmark;



import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import Link from 'components/reusableComponents/link/Link';
import { ChevronButton, BUTTON } from 'components/shared/ChevronButton';
import { useGoogleAnalytics } from 'hooks/useGoogleAnalytics';
import { cookieConfirmationContext } from 'components/layout/cookieBanner/CookieConfirmationContext';
import { useWebsiteConfigObject, useWebsiteConfig } from 'websiteConfig/useWebsiteConfig';
import { usePageContent } from 'api/acousticApi/ContentHooks';
import './CookieConfirmation.scss';

const CookieConfirmation = ({ currentPath, templateType, contentId }) => {
  const { trackPageView, trackEventCookies } = useGoogleAnalytics();
  const {
    showCookieBanner,
    updateShowCookieBanner,
    updateAnalyticsCookiesAccepted,
  } = useContext(cookieConfirmationContext);
  let {
    cookiesPath,
    tThisSiteUsesCookies,
    tPrivacyCookiePolicy,
    tAccept,
    tRefuse,
  } = useWebsiteConfigObject();

  const {
    activateFormRedesign
  } = useWebsiteConfig().websiteConfig || false
  
  let cookieContent = usePageContent(contentId);

  if (!showCookieBanner) {
    return null;
  }


  if(templateType === "Layout – Event Page" || templateType === "Layout – Event Thank You Page" )
  { 
    let cookieBanner = cookieContent?.content?.elements?.cookieBanner;
    if(cookieBanner !==null && typeof cookieBanner !== "undefined")
    {      
      cookiesPath = cookieBanner?.value?.elements?.cookiePageSlug.value;
      tThisSiteUsesCookies = cookieBanner?.value?.elements?.thisSiteUsesCookies.value;
      tPrivacyCookiePolicy = cookieBanner?.value?.elements?.privacyCookiePolicy.value;
      tAccept = cookieBanner?.elements?.value?.accept.value;
      tRefuse = cookieBanner?.elements?.value?.refuse.value;
    }       

  }
  
  return (
    <section className='cookie-confirmation-dialogue'>
      <div className='container'>
        <p>
          {tThisSiteUsesCookies}{' '}
          {cookiesPath && (
            <Link href={cookiesPath}>{tPrivacyCookiePolicy}</Link>
          )}
          .
        </p>
        <div className='controls'>
          <ChevronButton
            type={BUTTON}
            text={tAccept}
            onClick={() => {
              updateAnalyticsCookiesAccepted(true);
              updateShowCookieBanner(false, true);
              trackEventCookies(currentPath, document.title,'granted');
              activateFormRedesign={activateFormRedesign}
            }}
          />
          <ChevronButton
            type={BUTTON}
            text={tRefuse}
            className='blank'
            onClick={() => {
              updateAnalyticsCookiesAccepted(false);
              updateShowCookieBanner(false, false);
            }}
            activateFormRedesign={activateFormRedesign}
          />
        </div>
      </div>
    </section>
  );
};

CookieConfirmation.propTypes = {
  currentPath: PropTypes.string,
};

export { CookieConfirmation };

import React from 'react';
import { useWebsiteConfigObject,  useWebsiteConfig } from 'websiteConfig/useWebsiteConfig';
import PropTypes from 'prop-types';
import {
  useScreenWidth,
  SCREEN_WIDTH_SMALL,
  SCREEN_WIDTH_MEDIUM
} from 'components/layout/ScreenWidthContext';
import {
  ChevronButton,
  LINK,
} from 'components/shared/ChevronButton';
import {
  getValue,
  getHrefs,
  getColourHashValue,
} from 'utils/contentUtils';
import { getAssetUrl } from 'api/acousticApi';
import RichText from 'components/reusableComponents/richText/RichText';
import { useSelector } from 'react-redux';
import MobileBackground from 'assets/images/blob-bottom-origin.png';
import DesktopBackground from 'assets/images/blob-right-origin.png';
import { contentItemProptype } from 'propTypes/contentProptypes';
import './PromoBanner.scss';



const PromoBanner = ({
  content,
  darkerBg,
  whiteBg,
  shadow,
  backgroundSize
}) => {
  const { tLearnMore } = useWebsiteConfigObject();
  const {
    activateFormRedesign
  } = useWebsiteConfig().websiteConfig || null
  
  const {
    currentWidthRanges,
  } = useScreenWidth();

  const { redesign } = useSelector(state => state.ui);
 

  if (!content) {
    return null;
  }

  const contentValue = getValue(content);

  const {
    title,
    bodyText,
    closingText,
    linkText,
    link,
    backgroundImage,
    backgroundHexColour,
    buttonHexColour,
    textHexColour,
  } = contentValue;


  const titleValue = getValue(title);
  const linkValue = getValue(link);
  const closingTextValue = getValue(closingText);

  // This is currently hard coded on the front end. Ideally
  // they wanted a default value in acoustic but that wasn't possible
  const linkTextValue = linkText?.value ? linkText?.value : tLearnMore;
  const { internalHref, externalHref } = getHrefs({ link: linkValue });
  const isMobile = currentWidthRanges.includes(SCREEN_WIDTH_SMALL);
  const isTablet = currentWidthRanges.includes(SCREEN_WIDTH_MEDIUM);

  let image = getAssetUrl(backgroundImage);

  if (!internalHref && !externalHref) {
    return null
  }

  
  if(!image && !redesign) {
    image = isMobile ? MobileBackground : DesktopBackground;
  }
  
  const backgroundPosition = isMobile || isTablet? 'right bottom' : 'center right';

  const buttonColour = getColourHashValue(buttonHexColour);
  const backgroundColour = getColourHashValue(backgroundHexColour);
  const textColour = getColourHashValue(textHexColour);

  const style = {
    backgroundImage: `url(${image})`,
    backgroundPosition,
    backgroundRepeat: 'no-repeat',
    backgroundSize: backgroundSize ? backgroundSize : 'contain',
    backgroundColor: backgroundColour,
  };

  const buttonStyle = {
    backgroundColor: buttonColour
  };

  const textStyle = {
    color: textColour
  }

  return (
    <div
      className="promo-banner-component"
    >
      <div
        className={`promo-banner-component-background 
                    ${darkerBg ? 'promo-banner-component-background-bg-darker' : ''} 
                    ${whiteBg ? 'promo-banner-component-background-bg-white' : ''} 
                    ${shadow ? 'promo-banner-component-background-shadow' : ''}`}
        style={style}
      />
      <div className="inner-content">
        {
          titleValue && (
            <h2>{titleValue}</h2>
          )
        }
        {
          bodyText && (
            <RichText
              className="promo-banner-text"
              data={bodyText}
              style={textStyle}
            />
          )
        }
        {
          closingTextValue && (
            <h3>{closingTextValue}</h3>
          )
        }
        {
          linkText && linkTextValue && (
            <ChevronButton
              text={linkTextValue}
              className="promo-button"
              type={LINK}
              internalHref={internalHref}
              externalHref={externalHref}
              style={buttonStyle}
              vvClickAttr='click'
              activateFormRedesign={activateFormRedesign || false}
            />
          )
        }
      </div>
    </div>
  );
};

PromoBanner.propTypes = {
  content: contentItemProptype,
  darkerBg: PropTypes.bool,
  whiteBg: PropTypes.bool,
  shadow: PropTypes.bool,
};

export { PromoBanner };

import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { authActions } from 'redux/authentication/slice';
import { useWebsiteConfigObject, useWebsiteConfig } from 'websiteConfig/useWebsiteConfig';
import { useParams } from 'react-router-dom';
import { ChevronButton, LINK } from 'components/shared/ChevronButton';
import { LOGIN_PAGE_PATH } from 'routes/routeConstants';
import { GenericMessagePage } from 'components/pageTemplates/GenericMessagePage';
import { useGoogleAnalytics } from 'hooks/useGoogleAnalytics';

const SuccesfulRegistration = () => {
  const { guestUserId, guestUserEmail } = useSelector(state => state.authentication.status);

  const {
    tNextStep,
    tYoureAlmostThereEmail,
  } = useWebsiteConfigObject();

 

  const { trackSelfCertRegistrationFlows } = useGoogleAnalytics();
  const [gaSignUpEventSent, setGaSignUpEventSent] = useState(false);
  useEffect(() => {
    if(!gaSignUpEventSent && guestUserId){
      setGaSignUpEventSent(true);
      trackSelfCertRegistrationFlows("account_created", "KKI Hub confirm email", guestUserId);
    }
  },[guestUserId]);
  let registrationSuccessBody = tYoureAlmostThereEmail;
  if(tYoureAlmostThereEmail.includes("{email}") && guestUserEmail){
    registrationSuccessBody = registrationSuccessBody.replace("{email}", "<b>"+guestUserEmail+"</b>")
  } else {
    registrationSuccessBody = registrationSuccessBody.replace("{email}", "");
  }
  return (
    <GenericMessagePage
      titleText={tNextStep}
      headerText={registrationSuccessBody}
    />
  );
};

const ConfirmEmailFailure = () => {
  const params = useParams();
  const {
    errorCode,
  } = params;

  const {
    tSomethingWentWrong,
    tInvalidLink,
    tAnUnexpectedErrorGoToLogin,
    tTheLinkYouHaveFollowedConfirmationEmail,
    tGoToLoginPage,
    tError,
  } = useWebsiteConfigObject();

  const {
    activateFormRedesign
  } = useWebsiteConfig().websiteConfig ||false

  const is401Error = errorCode === '401';

  const titleText = is401Error ? tInvalidLink : tSomethingWentWrong;
  const headerText = is401Error ? tTheLinkYouHaveFollowedConfirmationEmail : tAnUnexpectedErrorGoToLogin;

  return (
    <GenericMessagePage
      categoryText={`${tError} ${errorCode}`}
      titleText={titleText}
      headerText={headerText}
    >
      <div className="center-align-children standard-margin-bottom">
        <ChevronButton
          text={tGoToLoginPage}
          type={LINK}
          internalHref={LOGIN_PAGE_PATH}
          activateFormRedesign={activateFormRedesign}
        />
      </div>
    </GenericMessagePage>
  );
};

const ConfirmEmailSuccess = () => {
  const {
    tSuccess,
    tYourRegistrationComplete,
    tGoToLoginPage,
  } = useWebsiteConfigObject();

  const {
    activateFormRedesign
  } = useWebsiteConfig().websiteConfig ||false;

  return (
    <GenericMessagePage
      titleText={tSuccess}
      headerText={tYourRegistrationComplete}
    >
      <div className="center-align-children standard-margin-bottom">
        <ChevronButton
          text={tGoToLoginPage}
          type={LINK}
          internalHref={LOGIN_PAGE_PATH}
          activateFormRedesign={activateFormRedesign}
        />
      </div>
    </GenericMessagePage>
  );
};


const ConfirmDeleteAccountFailure = () => {
  const params = useParams();
  const {
    errorCode,
  } = params;

  const {
    tSomethingWentWrong,
    tInvalidLink,
    tWeWereUnable,
    tTheLinkYouHaveFollowedDeletionProcess,
    tError,
  } = useWebsiteConfigObject();

  const is401Error = errorCode === '401';

  const titleText = is401Error ? tInvalidLink : tSomethingWentWrong;
  const headerText = is401Error ? tTheLinkYouHaveFollowedDeletionProcess : tWeWereUnable;

  return (
    <GenericMessagePage
      categoryText={`${tError} ${errorCode}`}
      titleText={titleText}
      headerText={headerText}
    />
  );
};


const ConfirmDeleteAccountSuccess = () => {
  const dispatch = useDispatch();

  const {
    tAccountRemoved,
    tYourAccountRemoved,
  } = useWebsiteConfigObject();
  

  useEffect(() => { 
    dispatch(authActions.global.logout({ withConfirm: false }))
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <GenericMessagePage
      titleText={tAccountRemoved}
      headerText={tYourAccountRemoved}
    />
  );
};


const SuccessfulProfileUpdate = () => {
  const {
    tAccountDetailsUpdated,
    tYourAccountDetailsUpdated,
  } = useWebsiteConfigObject();
  return (
    <GenericMessagePage
      titleText={tAccountDetailsUpdated}
      headerText={tYourAccountDetailsUpdated}
    />
  );
};

const SuccesfulForgottenPassword = () => {
  const {
    tNextStep,
    tYoureAlmostTherePassword,
  } = useWebsiteConfigObject();
  return (
    <GenericMessagePage
      titleText={tNextStep}
      headerText={tYoureAlmostTherePassword}
    />
  );
};

const PasswordResetSuccess = () => {
  const {
    tPasswordUpdated,
    tThankYouPasswordReset,
    tGoToLoginPage,
  } = useWebsiteConfigObject();

  const {
    activateFormRedesign
  } = useWebsiteConfig().websiteConfig || false

  return (
    <GenericMessagePage
      titleText={tPasswordUpdated}
      headerText={tThankYouPasswordReset}
    >
      <div className="center-align-children standard-margin-bottom">
        <ChevronButton
          text={tGoToLoginPage}
          type={LINK}
          internalHref={LOGIN_PAGE_PATH}
          activateFormRedesign={activateFormRedesign}
        />
      </div>
    </GenericMessagePage>
  );
};

const SuccessfulPasswordChange = () => {
  const {
    tPasswordChanged,
    tYourPasswordChanged,
  } = useWebsiteConfigObject();
  return (
    <GenericMessagePage
      titleText={tPasswordChanged}
      headerText={tYourPasswordChanged}
    />
  );
};


const SuccesfulDeleteProfile = () => {
  const {
    tNextStep,
    tWeHaveSent,
  } = useWebsiteConfigObject();
  return (
    <GenericMessagePage
      titleText={tNextStep}
      headerText={tWeHaveSent}
    />
  );
};

const ExternalLoginError = () => {

  const {
    texternalLoginErrorPageTitle,
    texternalLoginErrorPageSubHeading,
    texternalLoginErrorPageButtonText,
  } = useWebsiteConfigObject();

   const {
    activateFormRedesign
  } = useWebsiteConfig().websiteConfig || false;


  return (
    <GenericMessagePage
      titleText={texternalLoginErrorPageTitle}
      headerText={texternalLoginErrorPageSubHeading}
    >
      <div className="center-align-children standard-margin-bottom">
        <ChevronButton
          text={texternalLoginErrorPageButtonText}
          type={LINK}
          internalHref={LOGIN_PAGE_PATH}
          activateFormRedesign={activateFormRedesign}
        />
      </div>
    </GenericMessagePage>
  );
};



export {
  SuccesfulRegistration,
  ConfirmEmailFailure,
  ConfirmEmailSuccess,
  ConfirmDeleteAccountFailure,
  ConfirmDeleteAccountSuccess,
  SuccessfulProfileUpdate,
  SuccesfulForgottenPassword,
  PasswordResetSuccess,
  SuccessfulPasswordChange,
  SuccesfulDeleteProfile,
  ExternalLoginError,
};

import React ,{useState}from 'react';
import RichText from 'components/reusableComponents/richText/RichText';
import BookmarkList from './BoomarkList';
import json from './Bookmark.json'; // add it on local to see boookmark
// line 79 use this on local <BookmarkList data= {details || json} deleteFunction={deleteFunction}></BookmarkList> 
import { useSelector } from 'react-redux';
import { getEvent,deleteEvent } from 'api/dynamoApi';
import { getUrlCountryPath } from 'utils/urlUtils';
//scss
import './BookmarkTab.scss';


const BookmarkTab = ({
  tabId,
  tabActive,
  section,  
}) => {
  const { sectionDescription, sectionTitle } = section?.elements;
  const { data } = useSelector((state) => state.authentication);
  const { user } = data || {};
  const [details, setDetails] = useState([]);
  const [CountBookmark, setCountBookmark] = useState([0]);
  const [isFetching, setIsFetching] = useState(false);
  const userId = user.userId;
  const { token } = data?.token;

  if (tabId !== tabActive) {
    return '';
  }
  let type = "bookmark";
 
  if (!isFetching) {
    setIsFetching(true);
    getEvent({ userId, token, type })
  .then((response) => {
    
  if (response?.status === 200) {
   
    //apply filter based on country code
    const bookmarklist = response?.data?.filter((x)=> {return x.pageUrl?.toLowerCase().split('/').indexOf(getUrlCountryPath().toLocaleLowerCase())>-1?x:null});
    setDetails(bookmarklist);
    setCountBookmark(bookmarklist?.length);

    return (details,CountBookmark);
  }
  }).catch((error) => {
    console.log(" catch",error); 
    // const bookmarklist = json?.filter((x)=> {return x.pageUrl?.toLowerCase().split('/').indexOf(getUrlCountryPath().toLocaleLowerCase())>-1?x:null});
    // setCountBookmark(bookmarklist.length);
    //  setDetails(bookmarklist)
  });
}
 
 
//  getEvent(userId,token,type)
  //replace json with details to get event
// Delete function
const deleteFunction = async (contentId, recordId) => {
  const body = {
    userId,
    externalId: contentId,
    recordId,
    type,
  };

  try {
    
    const response = await deleteEvent({ body, token, userId });
    if (response?.error) {
      throw new Error('Error deleting bookmark');
    }

    // Update local state
    const updatedDetails = details.filter(
      (item) => item?.pageId !== contentId && item.recordId !== recordId
    );
    setDetails(updatedDetails);
    setCountBookmark(updatedDetails.length);
  } catch (error) {
    console.error("Error deleting bookmark:", error);
    console.log(details)
    // const updatedDetails = details.filter(
    //   (item) => item?.pageId !== contentId && item.recordId !== recordId
    // );
    // setDetails(updatedDetails);
    // console.log("details update",details)
    // setCountBookmark(updatedDetails.length);
  }
};


  return (
    <div className='my-hub-bookmarks'>
      <h3 className='my-hub-bookmarks__title'>{sectionTitle?.value}</h3>
      <div className='my-hub-bookmarks__desc'>
        <RichText data={sectionDescription} /> <span>({CountBookmark || 0})</span>
      </div>
      <div>
        <BookmarkList details= {details} deleteFunction={deleteFunction}></BookmarkList> 
      </div>
    </div>
  );
};

export default BookmarkTab;


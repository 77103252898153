import { getQuery, getQueryWithParams, getQueryWithBaseAuth, getQueryWithParamsWithoutCreds } from 'api/AxiosQueries';
import {
  domain,
  SEARCH_URL,
  SEARCH_URL_RENDER,
  RENDER_CONTEXT_URL,
  PREVIEW_MODE,
  BASIC_AUTH_GET_URL,
  BASIC_AUTH_GET_PREVIEW_URL,
  UTM_URL
} from './paths';

// retrieve a nested content item from within acoustic using the contentId
const getAPIContent = (contentId) => {
  const url = `${RENDER_CONTEXT_URL}/${contentId}`;
  if (PREVIEW_MODE) {
    return getQueryWithBaseAuth(BASIC_AUTH_GET_URL, BASIC_AUTH_GET_PREVIEW_URL).then(() => {
      return getQuery(url).then((response) => {
        return response.data
      })
    });
  }
  return getQuery(url).then((response) => response.data);
};

const searchQuery = (params) => {
  const url = SEARCH_URL;
  return getQueryWithParams(url, params);
};

const getUTMCampaigns = (params) => {
  const url = UTM_URL;
  return getQueryWithParams(url, params);
}

const searchQueryRendering = (params) => {
  const url = SEARCH_URL_RENDER;
  return getQueryWithParamsWithoutCreds(url, params);
};

// Retrieve resource url for a video element
// TODO waiting for feedback from haze whether we should use element.url or videoAsset.resourceUri to get the video
// both urls seem to work
const getAssetUrl = (element) => {
  if (!element || !element.url) return null;
  return `${domain}/${element.url}`;
};

const getAssetFileName = (element) => {
  if (!element) return null;
  const fileName = element.asset && element.asset.fileName;
  if (!fileName) {
    return '';
  }
  return fileName;
};


function formatFileSize(bytes, decimalPoints = 2) {
  if (bytes === 0) return '0 Bytes';

  const units = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB'];
  const k = 1024;
  const i = Math.floor(Math.log(bytes) / Math.log(k));
  const size = bytes / Math.pow(k, i);

  return `${size.toFixed(decimalPoints)} ${units[i]}`;
}



const getAssetFileSize = (element) => {
  if (!element) return null;
  const fileSize = element.asset && element.asset.fileSize;

  return formatFileSize(fileSize);
};

function removeExtension(filename) {
  return filename.replace(/\.[^/.]+$/, '');
}

const getAssetAlt = (element) => {
  if (!element) return null;
  const altText = element.asset && element.asset.altText;
  if (!altText) {
    return '';
  }
  return altText;
};

const getAssetLink = (element) => {
  if (!element) return null;
  const url = element.link && element.link.url;
  if (!url) {
    return '';
  }
  return url;
};
const getAssetTarget = (element) => {
  if (!element) return null;
  const target = element.link && element.link.target;
  if (!target) {
    return '';
  }
  return target;
};
const getImgAttr = (element) => {
  const src = getAssetUrl(element);
  const alt = getAssetAlt(element);
  const link = getAssetLink(element);
  const target = getAssetTarget(element);

  return ({
    src,
    alt,
    link,
    target
  });
};

export {
  getAPIContent,
  searchQuery,
  getAssetUrl,
  getAssetFileName,
  getImgAttr,
  searchQueryRendering,
  getUTMCampaigns,
  removeExtension,
  getAssetFileSize
};

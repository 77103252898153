import React from 'react';
import PropTypes from 'prop-types';
import Link from 'components/reusableComponents/link/Link';
import { fieldPropTypes } from 'components/shared/form/formPropTypes';
import './FieldLink.scss';

const FieldLink = ({
  field,
  fieldType,
  className
}) => {

  const {
    typeParams,
    size = 100,
  } = field;

  const {
    href,
    text,
  } = typeParams;

  const fieldBaseClassName = 'form-field';
  const fieldClassNames = [
    fieldBaseClassName,
    `${fieldBaseClassName}-${fieldType}`,
    `${fieldBaseClassName}--${size}`,
    className
  ];


  return (
    <div
      className={fieldClassNames.join(' ')}
    >
      <Link
        href={href}
      >
        {text}
      </Link>
    </div>
  );
};

FieldLink.propTypes = {
  field: fieldPropTypes,
  fieldType: PropTypes.string,
};


export {
  FieldLink,
};



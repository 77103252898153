import React,{useState, useEffect, useRef} from 'react';
import PropTypes from 'prop-types';
import {
  fieldPropTypes,
  formValuesPropTypes,
  inlineErrorsPropTypes,
  touchedPropTypes,
} from 'components/shared/form/formPropTypes';
import {
  InlineError,
} from 'components/shared/form/InlineError';
import './Input.scss';
import { ShowPassWord,NewHidePassWord, HidePassWord } from '../SvgIcons';
import { Alert } from '../SvgIcons';
import { useWebsiteConfigObject } from 'websiteConfig/useWebsiteConfig';


const Input = ({
  field,
  fieldType,
  formValues,
  setFormValues,
  inlineErrors,
  touchedFields,
  setTouchedFields,
  requiredLabel,
  invalidFieldLabel,
  className // call classname
}) => {

  const {
    tCapsLockLabel,
  } = useWebsiteConfigObject();

  const {
    label,
    id,
    name,
    typeParams,
    mandatory,
    pattern,
    size = 100,
    reverseLabel=false,
    minlength,
    maxlength,
  } = field;
  const {
    type,
    // stuff that html likes. Used for stuff like remembering password and username ect
    autoComplete,
    placeholder,
    readOnly,
    takeFullWidth,
    lengthErrorMsg,
  } = typeParams;
  const regex = new RegExp(pattern);
  const value = formValues[id];
  const touched = touchedFields[id];
  const inlineError = mandatory ? inlineErrors[id] : "";
  const viewableError = InlineError && touched;
  const [error, setError] = useState(false);
  const [chars, setChars] = useState(0);
  const [password, setPassword] = useState(false);
  const [capslock, setCapslock] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const requiredInput = (viewableError && inlineError && className) ? 'required-input' : '';


  const onChange = (e) => {
    e.preventDefault();
    const newValue = e.target.value;
    validateField(newValue);
  };
  const ref = useRef();
  function valiedateFieldLength (value) {
    let trimmedText = "";
    let numChars = 0;
    if(value !== ""){
      trimmedText = value.trim();
      numChars = trimmedText ? trimmedText.length : 0;
      setChars(numChars);
      if(maxlength) {
        if(numChars > maxlength) {
          let trimmedString = trimmedText.substring(0, maxlength);
          setFieldValue(trimmedString);
          setChars(maxlength);
          setFormValues((prevState) => ({
            ...prevState,
            [id]: trimmedString,
          }));
        }
      }
      if(minlength && minlength > 0) {
        if(numChars < minlength) {
          setErrorMsg(lengthErrorMsg || "Field must contain at least "+minlength+" characters");
          setError(true);
        }
      }
    } else {
      setChars(0);
    }
  }

  function validateField (value){
    if (regex.test(value)) {
      setError(false);
      setFieldValue(value);
      setFieldValidate(value);
      setFormValues((prevState) => ({
        ...prevState,
        [id]: value,
      }));
      valiedateFieldLength(value);
    }
    else{
      if(type != "email") {
        setFieldValue(value);
        setFieldValidate(value);
        setFormValues((prevState) => ({
          ...prevState,
          [id]: value,
        }));
        setError(true);
        setErrorMsg(invalidFieldLabel);
        valiedateFieldLength(value);
      } else {
        setFieldValue(value);
        setFieldValidate(value)
        setFormValues((prevState) => ({
          ...prevState,
          [id]: value,
        }));
        setError(true);
        setErrorMsg(invalidFieldLabel);
        valiedateFieldLength(value);
      }
    }
  }
  const setTouched = () => setTouchedFields((prevState) => ({
    ...prevState,
    [id]: true,
  }));

  const showHide = (e) => {
    setPassword(!password)
    password ? ref.current.type = "password" : ref.current.type = "text"
  }

  const detectCapsLock = () => {
    ref.current.addEventListener("keyup", function(event) {
      if (event.getModifierState && event.getModifierState("CapsLock")) {
        setCapslock(true)
      } else {
        setCapslock(false)
      }
    }); 
  }

  const fieldBaseClassName = 'form-field';
  const fieldClassNames = [
    fieldBaseClassName,
    `${fieldBaseClassName}-${fieldType}`,
    `${fieldBaseClassName}--${size}`,
    className !== 'undefined' ? className:  ""
  ];

  if (readOnly) {
    fieldClassNames.push(`${fieldBaseClassName}-${fieldType}-read-only`);
  }
  const [fieldValidate, setFieldValidate] = useState('');

  const [fieldValue, setFieldValue] = useState('');
  if(!label && !mandatory){
    return null;
  }
  

  const HidePassWordIcon = className == "form-redesign" ? NewHidePassWord : HidePassWord;

  return (
    <>
      <div
        className={fieldClassNames.join(' ')}
      >
        <div className="field-meta-data">
          {
            label && (
              <label
                htmlFor={id}
              >
                {!reverseLabel && label}
                {
                  mandatory && (
                    <span
                      className="mandatory-astrix"
                    >
                      *
                    </span>
                  )
                }
                {reverseLabel && label}
              </label>
            )
          }
          {
            !className && viewableError && inlineError && (
              <InlineError
                inlineErrorText={requiredLabel || inlineError}
              />
            )
          }
        </div>
        <div className= "input-section">
          {
            type === "password" && (
                password ? 
                <div className="show-password" onClick={showHide}><ShowPassWord /></div> : <div className="hide-password" onClick={showHide}><HidePassWordIcon /></div>
            )
          }
          <input
            ref={ref}
            className={`custom-focus text-field ${requiredInput}`}
            id={id}
            name={name}
            required={mandatory}
            onBlur={setTouched}
            value={value}
            onChange={readOnly ? () => null : onChange}
            type={type}
            autoComplete={autoComplete}
            placeholder={placeholder}
            readOnly={readOnly}
            pattern="/^[a-zA-Z]+$/"
            onFocus={type === "password" && detectCapsLock}
          />
          {
            className && viewableError && inlineError && (
              <InlineError
                inlineErrorText={requiredLabel || inlineError}
              />
            )
          }
          {
            fieldValidate && errorMsg && (
              error && <p className="error textarea-error">{errorMsg}</p>
            )
          }
        </div>
        {
          capslock && tCapsLockLabel && (
            <p className="error capslock"><Alert /> {tCapsLockLabel}</p>
          )
        }
        
      </div>
    {takeFullWidth && <div className={fieldClassNames.join(' ')}></div>}
    </>
  );
};

Input.propTypes = {
  field: fieldPropTypes,
  fieldType: PropTypes.string,
  formValues: formValuesPropTypes,
  setFormValues: PropTypes.func,
  inlineErrors: inlineErrorsPropTypes,
  touchedFields: touchedPropTypes,
  setTouchedFields: PropTypes.func,
};


export {
  Input,
};



import { React, useState } from 'react';
import RichText from 'components/reusableComponents/richText/RichText';
// import Breadcrumbs from 'components/layout/header/components/breadcrumbs/Breadcrumbs';
import { getUrlCountryPath } from 'utils/urlUtils';
import './BookmarkTab.scss';
import { Bin, ChevronLeft, ChevronRight } from 'components/shared/SvgIcons';
import Button from 'components/kanso/atoms/button/Button';
import { useSelector } from 'react-redux';



const generateBreadcrumbs = (url) => {
  const parts = url && url?.split("/").filter(Boolean).slice(1); // Split by '/' and remove the first part
  const breadcrumbs = [];

  if (getUrlCountryPath()) {
    breadcrumbs.push(
      <a href={`/${getUrlCountryPath()}`} key="home">
        Home
      </a>
    );
  }

  let path = "";
  parts?.forEach((part, index) => {
    path += `/${part}`;
    const isLast = index === parts.length - 1;

    breadcrumbs.push(
      <a
        key={index}
        href={path}
        className={isLast ? "active" : ""}
        aria-current={isLast ? "page" : undefined}
        style={{ marginRight: "10px", textDecoration: "none" }}
      >
        {part.replace(/-/g, " ").replace(/^\w/, (c) => c.toUpperCase())}
      </a>
    );
  });

  return breadcrumbs;
};

  
  const Breadcrumbs = ({ url }) => {
    return <ul className="Breadcrumbs_List">{generateBreadcrumbs(url)}</ul>;
  };
  const BookmarkItem = ({
    title,
    description,
    contentId,
    url,
    recordId,
    deleteFunction
  }) => {
  let local=`/${url}`
  
    return (
      // to do: is to check for the props through the breadcrumb + add some classnames for the css of breadcrumb
      <div className='bookmark-container'>
        <div className='breadcrumb'>
          <Breadcrumbs url={url} />
        </div>
        <div className='bookmark-content'>
          <h3 className='bookmark-title'>{title}</h3>
          <div className='bookmark-description-wrapper'>
            <p className='bookmark-description'>{description}</p>
            <div className='bookmark-buttons'>
              <button
                className='visit-button'
                onClick={() => window.open(local, '_blank')}
              >
                Visit
              </button>
              <button
                className='delete-button'
                onClick={() => deleteFunction(contentId,recordId)}
              >
               <Bin />
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  };

// Main Component with Toggle
const BookmarkList = ({ details ,deleteFunction}) => {
    
    const { data } = useSelector((state) => state.authentication);
    const { user } = data || {};
    const userId = user.userId;
    const { token } = data?.token;
  // nned to put safe check and if the the data is null, we need to add a message
  const [currentPage, setCurrentPage] = useState(1);
  let [paginatedItems, setPaginatedItems] = useState();
  const itemsPerPage = 10; // Number of items per page

  const displayedItems = details;


  const totalPages = displayedItems && Math.ceil(displayedItems?.length / itemsPerPage);

  // Get the items for the current page
  paginatedItems = displayedItems &&  displayedItems.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  // Function to handle page changes
  const handlePageChange = (page) => {
    if (page >= 1 && page <= totalPages) {
      setCurrentPage(page);
    }
  };

//   const deleteFunction =async (contentId,recordId)=> {
//    console.log("contentId:",contentId)
//     let body= {
//       "userId": userId, //user id
//       "externalId": contentId, //page id
//       "recordId": recordId, //primary key for bookmark record
//       "type": "bookmark" 
//     }
//     try {
//       // Make the API call to delete the bookmark
//       const response = await deleteEvent({ body,token,userId });

//       if (response.error) {
//         throw new Error('Failed to save bookmark');
//       }

//       console.log("Bookmark saved successfully");
//         // Retrieve current bookmarks or initialize an empty array if 'bookmarkIds' doesn't exist
//       const bookmarkedIds = JSON.parse(localStorage.getItem('bookmarkIds')) || [];
//       bookmarkedIds.pop(contentId);
//       localStorage.setItem('bookmarkIds', JSON.stringify(bookmarkedIds));
//       const updatedItems = paginatedItems.filter(
//         (item) => item.data?.externalId !== contentId && item.recordId !== recordId
//       );
//       setPaginatedItems(updatedItems);

//       // Handle pagination edge case: adjust currentPage if necessary
//       if ((currentPage - 1) * itemsPerPage >= updatedItems.length && currentPage > 1) {
//         setCurrentPage(currentPage - 1);
//       }
      
//     } catch (error) {
//       console.error('Error saving bookmark:', error);
//       const bookmarkedIds = JSON.parse(localStorage.getItem('bookmarkIds')) || [];
//       bookmarkedIds.pop(contentId);
//       localStorage.setItem('bookmarkIds', JSON.stringify(bookmarkedIds));
//       const updatedItems = paginatedItems.pop(
//         (item) => item.recordId !== recordId
//       );
//       setPaginatedItems(updatedItems);
//       console.log("paginated,", paginatedItems)
//        // Handle pagination edge case: adjust currentPage if necessary
//        if ((currentPage - 1) * itemsPerPage >= updatedItems.length && currentPage > 1) {
//         setCurrentPage(currentPage - 1);
//       }
    
//     }
    
//   }


  return (
    <div className='bookmark_section'>
      {/* Display items for the current page */}
      { paginatedItems && paginatedItems.map((item) => (
        <BookmarkItem
          recordId={item?.recordId}
          title={item?.pageTitle}
          description={item?.pageDescription}
          url={item?.pageUrl}
          contentId={item?.data?.externalId}
          template={item?.data?.template}
          deleteFunction={deleteFunction}
          
        />
      ))}

      {/* Pagination controls */}
      {paginatedItems && paginatedItems.length>0 && (
      <div className='pagination_controls' style={{ marginTop: '20px' }}>
        {/* Previous Button */}
        <button
          onClick={() => handlePageChange(currentPage - 1)}
          disabled={currentPage === 1}
          className='pagination-icon-button'
        >
          <ChevronLeft />
        </button>

        {/* Page Numbers */}
        {Array.from({ length: totalPages }, (_, index) => (
          <button
            key={index + 1}
            onClick={() => handlePageChange(index + 1)}
            className={`pagination-number-button ${currentPage === index + 1 ? 'current-page': ''}`}
          >
            {index + 1}
          </button>
        ))}

        {/* Next Button */}
        <button
          onClick={() => handlePageChange(currentPage + 1)}
          disabled={currentPage === totalPages}
          className='pagination-icon-button'
        >
          <ChevronRight />
        </button>
      </div>
      )}
    </div>
  );
};

export default BookmarkList;

import React, { useState, useEffect } from 'react';
import './Header.scss';

import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import Logo from './components/logo/Logo';
import MobileToggle from './components/mobileToggle/MobileToggle';
import SearchBox from './components/searchBox/SearchBox';
import UserStatus from './components/userStatus/UserStatus';
import MobileNavigation from './components/mobileNavigation/MobileNavigation';
import DesktopNavigation from './components/desktopNavigation/DesktopNavigation';
import Breadcrumbs from './components/breadcrumbs/Breadcrumbs';
import DesktopSubNavigation from './components/desktopNavigation/DesktopSubNavigation';
import ToastBar from './components/toastBar/ToastBar';
import { getUrlCountryPath } from 'utils/urlUtils';
import { useWebsiteConfigObject } from "websiteConfig/useWebsiteConfig";
import CountrySelector from './components/countrySelector/CountrySelector';
// import Bookmark from 'components/reusableComponents/Bookmark/Bookmark';

const Header = (props) => {
  /**
   * SearchBox and UserStatus to be removed for events library only
   * getUrlCountryPath() is used to get the country, but in the case of events the the function return events for all languages
   */
  const { template, contentId, status } = props;
  
  const library = getUrlCountryPath();
  // Mobile Nav State
  const { mobileNavStatus } = useSelector((state) => state.ui);
  const { main } = useSelector(state => state.ui.navigation);
  const { redesign } = useSelector(state => state.ui);

  
  const [hasBg, setHasBg] = useState(true);
  const history = useHistory();
  const {
    tToastBarText,
    adverseReportingUrl,
    tAdverseReportingLinkText,
    adverseReportingLinkLogo,
    tDisplaySearchResultsBox,
  } = useWebsiteConfigObject();

  let isEvent = false;
  if(template == "Layout – R5 – Event Page" || template == "Layout – R5 – Event Thank You Page" ){
    isEvent = true;
  }
  useEffect(()=> {
    let elements = document.getElementsByClassName("level-0");
    if(elements[0]?.classList.contains("active")){
      setHasBg(false);
    } else if(elements[1]?.classList.contains("active")){
      setHasBg(false);
    } else {
      setHasBg(true);
    }
  },[history.location.pathname, main])
  // TODO : Replace div with header tag.
 
  return (
    <div className={`Header ${redesign ? ' Header--redesign' : ''}${mobileNavStatus ? ' Header--open' : ''}${hasBg ? ' Header--bg' : ''}${tDisplaySearchResultsBox ? " Header--search" : ""}${library ? '':' global_header'}`}>
      {library && (tToastBarText?.value || adverseReportingUrl || tAdverseReportingLinkText || adverseReportingLinkLogo?.value  ? <ToastBar toastbarText={tToastBarText} adverseReportingUrl={adverseReportingUrl} adverseReportingLinkText={tAdverseReportingLinkText} adverseReportingLinkLogo={adverseReportingLinkLogo} /> : null )}
      <div className={`Header_Content${library === "events" ? " events-header" : ""} ${library ? '':'global_padding'}`} style={{ height: '80px' }}>
        <Logo library={library} />
        <DesktopNavigation />
        {library && library !== "events" && <SearchBox isLinkActive={hasBg} />}
        {library && library !== "events" && <UserStatus />}
        {library && redesign && library !== "events" && <CountrySelector isMobile={false} />}
        {library && library !== "events" && <MobileToggle />} 
        {/* {library && library !== "events" && status && !status.limitedAccess && !status.anonymous && !status.loggedIn && <Bookmark contentId={contentId} template={template}/>}  */}
        
      </div>
      
      <DesktopSubNavigation />
      <MobileNavigation />
    </div>
  );
};

export default Header;

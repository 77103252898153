import React, { useEffect, useState } from 'react';
import './AppLayout.scss';

import PropTypes from 'prop-types';
import { useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { setUserNavigation, setMainNavigation, setGraphicalBanners } from 'redux/ui/slice';
import { setLimitedAccess, setUserAsBot } from 'redux/authentication/slice';
import Header from './header/Header';
// import { Footer } from './Footer';
import { MainArea } from './MainArea';
import { ScrollingElement } from './ScrollingElement';
import { OWA_AUTH } from 'utils/authUtils';
import { useWebsiteConfigObject, useWebsiteConfigDeep } from 'websiteConfig/useWebsiteConfig';
import { VerifyNotification } from 'components/layout/VerifyNotification';
import { CookieBannerWithPreferences } from './cookieBanner/CookieBannerWithPreferences';
import { CookieConfirmation } from './cookieBanner/CookieConfirmation';
import { DefaultSEOHeaders } from './DefaultSeoHeaders';
import {
  useAPINavigation,
  useAuthServiceNavigation,
} from './header/navigationHooks';
import { useWebinarsQuery } from 'routes/useApiRoutes';
import { useApiRoutes } from 'routes/useApiRoutes';
import OneTrustCookie from 'components/shared/onetrustCookie/OneTrustCookie';
import { useGoogleAnalytics } from 'hooks/useGoogleAnalytics';
import { setGaStatus } from 'redux/authentication/slice';
import { ContentGateWrapper as ContentGateWrapperLimitedAccess } from "components/layout/ContentGateWrapperLimitedAccess";
import { ContentGateWrapper as ContentGateWrapperTC } from "components/layout/ContentGateWrapperTC";
import { EncapsulationLayout } from './EncapsulationLayout';
import { MoodleRedirections } from './MoodleRedirections';
import { setAuthEncapsulation, setRedesign } from 'redux/ui/slice';
//const 
import { BOT_LIST } from 'utils/botNames';
import { Maintenance } from './Maintenance';
import GTag from 'components/shared/ga/gtag';
import PersonalizedMessage from './personalizedMessage/PersonalizedMessage';
import { setWebinars } from 'redux/user/slice';
import Giosg from 'components/shared/giosg/Giosg';
import QuizTemplate from 'pages/quizTemplate/QuizTemplate';
import { getValue } from 'utils/utilityHelper';
import { Features } from 'components/shared/feature/Features';
import Sfmc from 'components/shared/sfmc/Sfmc';
import { SFMC } from 'api/acousticApi/ContentTypesFeatures';
import json from '../pageTemplates/myhub/components/Bookmark.json';
import { getEvent } from 'api/dynamoApi';
import useUtmUnlock, { ExpiredCampaignModal } from 'utmCampaign/useUtmUnlock';


const AppLayout = ({ service }) => {
  // Redux
  const dispatch = useDispatch();
  let botPattern = BOT_LIST;
  let reg = new RegExp(botPattern, 'i');
  let userAgent1 = navigator.userAgent; 
  if (reg.test(userAgent1)) {
    dispatch(setUserAsBot());
  }
  const { webinars, isLoading } = useWebinarsQuery();
  const [showConsent, setShowConsent] = useState(false);
  const [isNewDesign, setNewDesign] = useState(true);
  const { startQuiz, content } = useSelector((state) => state?.quiz?.quizData)
  const { status, data } = useSelector((state) => state.authentication);
  const { limitedAccessConditions, features, graphicBanner } = useWebsiteConfigDeep().websiteConfig ?? {};
  const [isFetching, setIsFetching] = useState(false);
  const { user } = data || {};
  const userId = user.userId;
  const type ="bookmark"
  const { token } = data?.token;
  useEffect(() => {
    if(!isLoading && webinars){
      dispatch(setWebinars(webinars));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading]);
  useEffect(() => {
    if(graphicBanner?.values) dispatch(setGraphicalBanners(graphicBanner?.values))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [graphicBanner])
  useEffect(() => {
    if(features){
      if(features.length > 0){
        for(let z=0; z < features.length ; z++){
          if(features[z]?.elements?.name?.value?.selection === "encapsulationFeature" && features[z]?.elements?.activeFeature?.value) {
            dispatch(setAuthEncapsulation());
          }
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [features]);

  useEffect(() => {
    if(isNewDesign){
      document.body.classList.remove('body--v1');
      document.body.classList.add('body--v2');
    } else {
      document.body.classList.remove('body--v2');
      document.body.classList.add('body--v1');
    }
  },[isNewDesign])

  useEffect(() => {
    const hash = location.hash
    if(hash) {
      setTimeout(() => {
        const section = document.getElementById(hash.substring(1))
        if(section) {
          section.scrollIntoView({ behavior: 'smooth', block: 'start'});
        }
      }, 1)
    }
  })

  useEffect(() => {
    if(limitedAccessConditions && limitedAccessConditions?.values){
      const { tokenResponse } = data?.additional;
      if(tokenResponse){
        for(let i=0; i < limitedAccessConditions?.values.length ; i++){
          const { fieldKey , blacklistedValue } = limitedAccessConditions?.values[i]?.elements;
          //check if token reponse has a key same as fieldKey?.value
          if (Object.keys(tokenResponse).indexOf(fieldKey?.value) > -1 && !status?.limitedAccess) {
            const blacklistedValueArray = blacklistedValue?.value ? blacklistedValue?.value?.split("|") : [];
            let tokenValue = tokenResponse[fieldKey?.value].toString();
            if(blacklistedValueArray?.includes(tokenValue)){
              dispatch(setLimitedAccess());
            }
          }
        }
      }
    }
  }, [limitedAccessConditions]);

  setTimeout(()=>{
    if(!showConsent){
      setShowConsent(!showConsent);
    }
  },3000);

  const { 
    routes, 
    // isLoading, 
    // error 
  } = useApiRoutes();


  const { trackEventWithMethod } = useGoogleAnalytics();
  const { utmState, expiredMessage } = useUtmUnlock();
  const { additional } = useSelector((state) => state.authentication.data);
  const { loggedIn } = useSelector((state) => state.authentication.status);
  if(loggedIn && additional?.tokenResponse?.userCreatedDate && !additional?.signUpGaEventRegistered){
    const today = new Date().toISOString();
    let currentDate = new Date(today).getTime();
    let registeredDate = new Date(additional?.tokenResponse?.userCreatedDate).getTime();
    let mins = (currentDate - registeredDate) / 60000;
    if(mins < 15){
      trackEventWithMethod('sign_up', 'KK Hub web');
    } else {
      trackEventWithMethod('login', 'KK Hub web');
    }
    dispatch(setGaStatus());
  }
  // Navigation Hooks
  const { navigation } = useAPINavigation();
  const userNavigation = useAuthServiceNavigation();
  // Set User Navigation
  useEffect(() => {
    dispatch(setUserNavigation(userNavigation));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userNavigation]);

  // Set Main Navigation
  useEffect(() => {
    dispatch(setMainNavigation(navigation));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [navigation]);

  const { 
    featureSwitchCookieBannerWithPreferences, 
    hideHeaderComponents, 
    oneTrustDomainId, 
    oneTrustScriptUrl,
    cookieBannerVersion,
    limitedProfileDescription,
    limitedProfileTitle,
    limitedProfileLabel,
    limitedProfileLink,
    tPersonalizedMessage,
    sfmcDataset
  } = useWebsiteConfigObject();

  let cookieVersionValue = cookieBannerVersion?.selection;
  const location = useLocation();
  const path = location.pathname;
  const absolutePath = window.location.pathname;
  let currentPageId = "";
  let currentTemplateType = "";
  const userAgent = window.navigator.userAgent;

  if(routes!=null)
  {    
    routes.forEach(element => {
      if(element.path === path)
      {
        currentPageId = element.contentId;
        currentTemplateType = element.templateType;
      }
    });
  } 

  if(startQuiz && getValue(content)) {
    return (
      <div className={`app-layout${isNewDesign ? " app-layout--v2" : " app-layout--v1"}`}>
        <QuizTemplate data={getValue(content)} />
      </div>
    )
  }
  if (!isFetching && loggedIn) {
    setIsFetching(true);
    getEvent({ userId, token, type})
    .then((response) => {
      console.log(response)
      if (response?.status === 200) {
        const externalIds = response?.data
          .map((bookmark) => bookmark?.pageId)
          .filter((id) => id !== null); // Remove null values
        localStorage.setItem("bookmarkIds", JSON.stringify(externalIds));
      }
  }).catch((error) => {
    console.log(" catch",error);
    // const externalIds = json
    //       .map((bookmark) => bookmark.data.externalId)
    //       .filter((id) => id !== null); // Remove null values
    //     localStorage.setItem("bookmarkIds", JSON.stringify(externalIds));
   
  });
}


  return (
    <div className={`app-layout${isNewDesign ? " app-layout--v2" : " app-layout--v1"}`}>
      <Maintenance service={service} />
      <MoodleRedirections />
      <DefaultSEOHeaders path={absolutePath} />
      <Features contentType={SFMC}>
        <Sfmc dataset={sfmcDataset} />
      </Features>
      <GTag />
      {tPersonalizedMessage && (
        <PersonalizedMessage
          message={tPersonalizedMessage}
        />
      )}
      {!hideHeaderComponents && <Header template={currentTemplateType} contentId={currentPageId} status={status} />}

      {service === OWA_AUTH && <VerifyNotification />}

      {status?.limitedAccess && status?.loggedIn && (limitedProfileDescription?.value || limitedProfileLabel || limitedProfileLink || limitedProfileTitle?.value) && <ContentGateWrapperLimitedAccess />}
      
      {utmState != "unlocked" &&  <EncapsulationLayout contentId={currentPageId} />}

      {!status?.limitedAccess && showConsent && <ContentGateWrapperTC />}

      <ScrollingElement />

      <MainArea />

      {
      cookieVersionValue === "OneTrust" ? 
        !userAgent.includes('VeevaWeb2PDFCrawler') && <OneTrustCookie id={oneTrustDomainId} url={oneTrustScriptUrl}/>  :
        (featureSwitchCookieBannerWithPreferences ? (
          <CookieBannerWithPreferences currentPath={absolutePath} contentId={currentPageId} templateType={currentTemplateType} />
        ) : (
          <CookieConfirmation currentPath={absolutePath} contentId={currentPageId} templateType={currentTemplateType} />
        ))
      }
      
    </div>
  );
};

AppLayout.propTypes = {
  service: PropTypes.string,
};

export { AppLayout };

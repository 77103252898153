import React from 'react'
import { contentElementProptype } from 'propTypes/contentProptypes'
import { stringToCssId } from 'utils/stringUtils'
import { getAssetUrl, getImgAttr } from 'api/acousticApi'

// modules
import RelatedContent from 'modules/relatedContent/RelatedContent'
import VideoCard from 'modules/videoCard/VideoCard'
import TwoColumnPanel from 'modules/twoColumnPanel/TwoColumnPanel'
import ExpandableContentPanels from 'modules/expandableContentPanels/expandableContentPanels'
import ImagePanelFooterCopy from 'modules/imagePanelFooterCopy/imagePanelFooterCopy'

// components
import RichText from 'components/reusableComponents/richText/RichText'
import Video from 'components/reusableComponents/video/Video'
import { PDFPreview } from 'components/reusableComponents/pdfPreview/PDFPreview'
import Image from 'components/reusableComponents/image/Image'
import Grid from 'layouts/grid/Grid'
import FormBuilder from 'modules/formBuilder/FormBuilder';
import {
  useScreenWidth,
  SCREEN_WIDTH_SMALL,
} from 'components/layout/ScreenWidthContext';

// styles
import './Content.scss'
import AudioCard from 'modules/audioCard/AudioCard'
import { getValue } from 'utils/contentUtils'
import CustomisableImageCard from 'modules/customisableImageCard/CustomisableImageCard'
import VeevaPortalViewer from 'modules/veevaPortalViewer/VeevaPortalViewer'
import { getUrlCountryPath } from 'utils/urlUtils'
import QuizAcousticData from 'components/kanso/patterns/quiz/QuizAcousticData'

const Content = ({ content, colour, cardClass, componentType = 'cards', settings, brandColours,pullQuoteBackground, pageContent }) => {
  const { currentWidthRanges } = useScreenWidth();


  if (!content) { return null; }
  const { locale, title, titleRichText, copy: text, image,externalVideoLink, video, videoTitle, webvttChaptersFile, subtitles, videoPoster, videoPosterImage, carousel, pdfPreview, videoCardGrid, backgroundColour, columnOne, columnTwo, audioFile, fileDownloadName,allowDownload } = content?.elements || {}

  if(locale?.value && !locale?.value?.toLowerCase().includes(getUrlCountryPath())) return null;

  const pdfUrl = getAssetUrl(pdfPreview)
  const brandColourStyle = {
      backgroundColor: `${colour?.value}`,
      color: '#FFFFFF'
  };

  const style = {
      backgroundColor: `${backgroundColour?.value}`
  };
  const isSmall = currentWidthRanges.includes(SCREEN_WIDTH_SMALL);

  const defaultSettings = {
      infinite: carousel?.value?.elements?.automateCarousel?.value ? true : false,
      dots: true,
      arrows: true,
      slidesToShow: 2,
      autoplay: isSmall ? false  : carousel?.value?.elements?.automateCarousel?.value ? true : false,
      autoplaySpeed: carousel?.value?.elements?.automationInterval?.value ? parseFloat(carousel?.value?.elements?.automationInterval?.value) * 1000 : 2000,
      responsive: [
          {
              breakpoint: 1023,
              settings: { slidesToShow: 1, arrows: false },
          },
      ],
  }

  const dividerHasTitle = title?.value || titleRichText?.value ? 'content__divider content__divider--has-title' : 'content__divider'
  const contentHasTitle = title?.value || titleRichText?.value ? 'content__inner content__inner--has-title' : 'content__inner'
  const contentNoPadding =
    columnOne ||
    content?.type === 'Module - Image panel with header and footer copy' ||
    content?.type === 'Module - Expandable Content Panels'
      ? 'content__inner--no-padding'
      : '';
  const id = stringToCssId(title?.value) || stringToCssId(titleRichText?.value);

  const isFullWidth = videoCardGrid?.value?.elements?.fullWidthVideoCards?.value

  const titleValue = getValue(title);
  const img = getImgAttr(image);
  let titleRichTextValue = getValue(titleRichText);

 

  if(audioFile?.url){
    const title = titleRichText?.value ? titleRichTextValue  : titleValue
    return (
      <AudioCard image={img} copy={text} src={audioFile} title={title} fileDownloadName={fileDownloadName} allowDownload={allowDownload} />
    )
  }

  if(content?.type === "Module - Icon Content Card"){
    return <CustomisableImageCard element={content}/>
  }

  if(content?.type === "Module - Veeva Portals Viewer"){
    return <VeevaPortalViewer element={content} />
  }

  if(content?.type === "Kanso-Pattern-Quiz"){
    return <div className="quiz-component"><QuizAcousticData data={{ ...content?.elements, embedded: true, pageContent }} /></div>
  }
  
  return (
    <div className='content__outer' id={id}>
      <div className={dividerHasTitle} style={brandColourStyle}></div>
      <div className={`${contentHasTitle} ${contentNoPadding}`} style={style}>
        {titleRichText?.value && <RichText data={titleRichText?.value?.text ? titleRichText?.value?.text : titleRichText } className='content__title' /> }
        {title?.value && !titleRichText?.value && (
          <h2 className='content__title'>{title.value}</h2>
        )}
        <RichText pullQuoteBackground={pullQuoteBackground} data={text} />
        {image?.url && (
          <div className='content__media-container'>
              <figure className='content__figure'>
                  <Image data={image} />
              </figure>
          </div>
        )}
        {(video?.url || externalVideoLink?.value) && (
          <div className='content__media-container'>
              <Video element={video} externalVideo={externalVideoLink} title={videoTitle} videoPoster={videoPoster} videoPosterImage={videoPosterImage} subtitles={subtitles} webvttChaptersFile={webvttChaptersFile} />
          </div>
        )}
        {carousel && (

            <RelatedContent
                content={carousel}
                componentType={componentType}
                brandCoulours={brandColours}
                bgColor='transparent'
                carouselSettings={settings || defaultSettings}
                cardTheme='secondary'
                alternateCardBg={true}
                fluid
            />
        )}
        {pdfUrl && (
          <PDFPreview pdfUrl={pdfUrl} src={pdfPreview} />
        )}
        {content?.type === "Component – Form Builder" &&
          <FormBuilder element={content}/>
        }
        
        {content?.type === "Module - Expandable Content Panels" &&
          <ExpandableContentPanels content={content} backgroundColor={brandColours?.value?.elements?.expandableContentBackground} />
        }
        {content?.type === "Module - Image panel with header and footer copy" &&
          <ImagePanelFooterCopy content={content} ctaBackground={brandColours?.value?.elements?.ctaButtonsBackground} backgroundColor={brandColours?.value?.elements?.imagePanelWithHeaderAndFooterBaclground} />
        }
        {columnOne && columnTwo && (
          <TwoColumnPanel
            columnOne={columnOne}
            columnTwo={columnTwo}
            linkText={content?.elements?.linkText?.value}
            linkUrl={content?.elements?.linkUrl?.value}
            ctaBackground={brandColours?.value?.elements?.ctaButtonsBackground}
            bgColor={brandColours?.value?.elements?.twoColumnPanelBackground}
          />
        )}
        {videoCardGrid?.value && (
          <RelatedContent bgColor='transparent' headingText={videoCardGrid?.value?.elements?.relatedVideosHeading?.value} fluid removeTopPadding>
            <Grid wrapperStyle={{ margin: 20 }} itemsPerRow={{ sm: 1, md: 2, lg: isFullWidth ? 1 : 3 }} gap={{ sm: 20, md: 25, lg: 25 }}>
              {videoCardGrid?.value?.elements?.relatedVideosVideoCards?.values?.map((video) => {
                  const { id, lastModified } = video || {}

                  const { videoCardContent, videoCardHeading,externalVideoLink:videoCardExternalLink, videoCardImage, videoCardAsset, videoCardLink, videoCardSubheading, videoCardVideo, webvttChaptersFile, subtitles, videoPoster, videoPosterImage } = video?.elements || {}
                  const assetUrl = getAssetUrl(videoCardAsset);
                  const href = assetUrl ? assetUrl  : videoCardLink?.linkURL;

                  return (
                      <VideoCard
                        key={id}
                        href={href}
                        image={videoCardImage}
                        video={videoCardVideo}
                        heading={videoCardHeading?.value}
                        subHeading={videoCardSubheading?.value}
                        content={videoCardContent?.value?.text}
                        postDate={lastModified}
                        className={cardClass}
                        style={brandColourStyle}
                        isFullWidth={isFullWidth}
                        webvttChaptersFile={webvttChaptersFile}
                        subtitles={subtitles}
                        videoPoster={videoPoster}
                        videoPosterImage={videoPosterImage}
                        fileAsset={videoCardAsset}
                        externalVideoLink={videoCardExternalLink}
                      />
                  )
              })}
            </Grid>
          </RelatedContent>
        )}
      </div>
    </div>
  );
};

Content.propTypes = {
  element: contentElementProptype,
};

export default Content;
import React, { useState, useContext } from 'react';
import PropTypes from 'prop-types';
import Link from 'components/reusableComponents/link/Link';
import { ChevronButton, BUTTON } from 'components/shared/ChevronButton';
import { cookieConfirmationContext } from 'components/layout/cookieBanner/CookieConfirmationContext';
import { CookieBannerModal } from 'components/layout/cookieBanner/CookieBannerModal';
import { useWebsiteConfigObject, useWebsiteConfig } from 'websiteConfig/useWebsiteConfig';
import { usePageContent } from 'api/acousticApi/ContentHooks';
import { useGoogleAnalytics } from 'hooks/useGoogleAnalytics';
import './CookieBannerWithPreferences.scss';


const CookieBannerWithPreferences = ({ currentPath, templateType, contentId }) => {
  const [showCookiePreferences, setShowCookiePreferences] = useState(false);
  const { trackPageView, trackEventCookies } = useGoogleAnalytics();
  const {
    showCookieBanner,
    updateShowCookieBanner,
    updateAnalyticsCookiesAccepted,
  } = useContext(cookieConfirmationContext);
  let {
    cookiesPath,
    tThisSiteUsesCookies,
    tPrivacyCookiePolicy,
    tAccept,
    tRefuse,
    tCookiePreferencesButtonText,
  } = useWebsiteConfigObject();

  const {
    activateFormRedesign
  } = useWebsiteConfig().websiteConfig  || false

  let cookieContent = usePageContent(contentId);

  if (!showCookieBanner) {
    return null;
  }

  if(templateType === "Layout – Event Page" || templateType === "Layout – Event Thank You Page" )
  { 
    let cookieBanner = cookieContent?.content?.elements?.cookieBanner;
    
    if(typeof cookieBanner !== "undefined" && cookieBanner !==null)
    {
      cookiesPath = cookieBanner?.value?.elements?.cookiePageSlug.value;
      tThisSiteUsesCookies=  cookieBanner?.value?.elements?.thisSiteUsesCookies.value;
      tPrivacyCookiePolicy=  cookieBanner?.value?.elements?.privacyCookiePolicy.value;
      tAccept=  cookieBanner?.value?.elements?.accept.value;
      tRefuse=  cookieBanner?.value?.elements?.refuse.value;
      tCookiePreferencesButtonText=  cookieBanner?.value?.elements?.cookiePreferencesButtonText.value;
    }      

  }
 
  const acceptAllCookiesHandler = () => {
    setShowCookiePreferences(false);
    updateAnalyticsCookiesAccepted(true);
    updateShowCookieBanner(false, true);
    trackEventCookies(currentPath, document.title,'granted');
  };

  const refuseCookiesHandler = () => {
    setShowCookiePreferences(false);
    updateAnalyticsCookiesAccepted(false);
    updateShowCookieBanner(false, false);
    trackEventCookies(currentPath, document.title,'denied');
  };

  const savePreferencesHandler = (optinAnalytics) => {
    setShowCookiePreferences(false);
    updateAnalyticsCookiesAccepted(optinAnalytics);
    updateShowCookieBanner(false, optinAnalytics);
    if (optinAnalytics) {
      trackEventCookies(currentPath, document.title,'granted');
    }
  };

  const closeCookiePreferencesModal = () => setShowCookiePreferences(false);

  return (
    <section className='cookie-banner' style={{ position: 'fixed' }}>
      <div className='container'>
        <p className='cookie-banner__text'>
          {tThisSiteUsesCookies}{' '}
          {cookiesPath && (
            <Link href={cookiesPath}>{tPrivacyCookiePolicy}</Link>
          )}
          .
        </p>
        <div className='cookie-banner__controls'>
          <div className='cookie-banner__controls-primary'>
            <ChevronButton
              type={BUTTON}
              text={tAccept}
              onClick={acceptAllCookiesHandler}
              vvClickAttr='click'              
              vvSequenceAttr="1"
              activateFormRedesign={activateFormRedesign}
            />
            <ChevronButton
              type={BUTTON}
              text={tRefuse}
              className='blank'
              onClick={refuseCookiesHandler}
              activateFormRedesign={activateFormRedesign}
            />
          </div>
          <div className='cookie-banner__controls-secondary'>
            <ChevronButton
              type={BUTTON}
              text={tCookiePreferencesButtonText}
              onClick={() => setShowCookiePreferences(true)}
              variant='text-link'
              activateFormRedesign={activateFormRedesign}
            />
          </div>
        </div>
      </div>
      <CookieBannerModal
        showModal={showCookiePreferences}
        onClose={closeCookiePreferencesModal}
        onSavePreferences={savePreferencesHandler}
        templateType={templateType}
        contentId={contentId}
      />
    </section>
  );
};

CookieBannerWithPreferences.propTypes = {
  currentPath: PropTypes.string,
};

export { CookieBannerWithPreferences };

import React from 'react';
import Modal from 'react-bootstrap/Modal';
import PropTypes from 'prop-types';

// styles
import './AudioDownloadModal.scss';
import { useWebsiteConfigObject } from 'websiteConfig/useWebsiteConfig';
import Divider from 'components/kanso/atoms/divider/Divider';
import Button from 'components/kanso/atoms/button/Button';

const AudioDownloadModal = ({ 
  showModal, 
  onClose, 
  onPrimaryButtonClick, 
  audioSize, 
  audioTitle }) => {
  const {
    audioDownloadModalTitle,
    audioDownloadSize,
    audioDownloadTitleText,
    audioDownloadCancelText,
    audioDownloadConfimationText,
  } = useWebsiteConfigObject();

  return (
    <Modal
      size='md'
      aria-labelledby='modal-title'
      centered
      show={showModal}
      animation={false}
      onHide={onClose}
      className='audio-download-modal'
    >
      <Modal.Header closeButton onClick={onClose}>
        <Modal.Title>{audioDownloadModalTitle}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Divider />
        <div className='modal-body__section'>
          <div>{`${audioDownloadSize}: ${audioSize}`}</div>
          <div>{`${audioDownloadTitleText}: ${audioTitle}`}</div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button
          text={audioDownloadConfimationText}
          icon='chevronRight'
          iconAlign='right'
          onClick={onPrimaryButtonClick}
        />
        <Button
          type='secondary'
          text={audioDownloadCancelText}
          onClick={onClose}
        />
      </Modal.Footer>
    </Modal>
  );
};

AudioDownloadModal.propTypes = {
  showModal: PropTypes.bool,
  onClose: PropTypes.func,
};

export default AudioDownloadModal;

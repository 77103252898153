import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { SearchIcon } from 'components/shared/SvgIcons';
import { useDispatch, useSelector } from 'react-redux';
import { recentUniqueSearchTerm, clearAllRecentSearchTerms, selectedPage } from 'redux/search/slice';
import { useWebsiteConfigObject } from 'websiteConfig/useWebsiteConfig';
import { closeMobileNav } from 'redux/ui/slice';
import { useGoogleAnalytics } from 'hooks/useGoogleAnalytics';
import { buildSearchParams } from 'utils/searchUtils';

import './SearchBox.scss';
import SearchBoxHistory from './SearchBoxHistory';

const SearchBox = ({ isLinkActive }) => {
  const {
    tSearchRelatedPageUrl,
    activateFormRedesign
  } = useWebsiteConfigObject();
  const searchPath = tSearchRelatedPageUrl ? tSearchRelatedPageUrl.split("?") : null

  const dispatch = useDispatch();
  const { tSearchPlaceholder, tDisplaySearchResultsBox, tCancel } = useWebsiteConfigObject();
  const { showRecentSearchHistory, maxRecentSearchTermsToDisplay, searchTerm } = useSelector((state) => state.search);
  const { trackSearchTerm } = useGoogleAnalytics();

  const recentSearchTerms = useSelector(recentUniqueSearchTerm);
  const showHistory = recentSearchTerms.length > 0 ? true : false;

  const [searchTermVal, setSearchTermVal] = useState(searchTerm);
  const [showRecentHistory, setShowRecentHistory] = useState(false);

  //This gives the number of items to diplay in the recent search term array
  const sliceArrayToXNum = recentSearchTerms.length >= maxRecentSearchTermsToDisplay ? -maxRecentSearchTermsToDisplay : 0;

  const history = useHistory();

  const handleSubmit = (termSelected) => {
    const searchText = (termSelected && typeof(termSelected) !== 'object') ? termSelected : searchTermVal

    dispatch(closeMobileNav());
    dispatch(selectedPage(1));
    setShowRecentHistory(false);

    if(searchText) trackSearchTerm(searchText);

    history.push({
      pathname: searchPath ? (searchPath[0].startsWith("/") ? searchPath[0] : "/"+searchPath[0]) : '/search-results',
      search: buildSearchParams(searchText, 1,searchPath ? searchPath[1] : null)
    })
  };

  const clearAll = () => {
    dispatch(clearAllRecentSearchTerms());
    setShowRecentHistory(false);
  };

  const recentTermSelected = (termSelected) => {
    setSearchTermVal(termSelected);    
    handleSubmit(termSelected);
    document.querySelector('.custom-focus').focus();
  };

  const handleOnChange = (e) => {
    setSearchTermVal(e.target.value);
    if (e.target.value !== '') {
      setShowRecentHistory(showHistory);
    }
  };

  const handleKeyPress = (e) => {
    if (e.charCode === 13) {
      handleSubmit();
    }
  };

  const handleCancel = () => {
    setSearchTermVal('');
  }

  const handleBlur = (e) => {
    if (!e.currentTarget.contains(e.relatedTarget)) {
      setShowRecentHistory(false);
    }
  }

  const isSearchResults = window.location.pathname.includes('search-results');
  // browsing away from search-results clears the search input field
  useEffect(() => {
    const selectedPathName = window.location.pathname.split('/')[2];
    if(selectedPathName === undefined || !isSearchResults) {
      setSearchTermVal('');
    }  
  }, [isSearchResults]);

  useEffect(() => {
    setSearchTermVal(searchTerm);
  }, [searchTerm]);

  const cancelText = tCancel ? tCancel.charAt(0) + tCancel.slice(1).toLowerCase() : 'Cancel';
  if (tDisplaySearchResultsBox) {
    return (
      <div className='SearchBox' tabIndex="1" onBlur={handleBlur}>
        <div className='SearchBox-Inner'>
          <div className={`form-field form-field-input form-field--100 ${activateFormRedesign&&"form-redesign"}`}>
            <button onClick={handleSubmit}>
              <SearchIcon />
            </button>
            <input
              className={`custom-focus${isLinkActive ? " custom-focus--border" : ""} search-box`}
              value={searchTermVal}
              onKeyPress={(e) => handleKeyPress(e)}
              onChange={(e) => handleOnChange(e)}
              autoComplete='off'
              placeholder={tSearchPlaceholder || "Search"}
              name='searchBox'
              type='text'
            />
          </div>
          {/* <div className='SearchBox-Cancel' onClick={() => handleCancel()}>
            {cancelText}
          </div> */}
        </div>

        {showRecentSearchHistory && showRecentHistory && recentSearchTerms && (
          <SearchBoxHistory
            recentSearchTerms={recentSearchTerms}
            clearAll={clearAll}
            recentTermSelected={recentTermSelected}
            maxRecentSearchTermsToDisplay={sliceArrayToXNum}
          />
        )}
      </div>
    );
  } else {
    return null;
  }
};

export default SearchBox;

import { AppConfig } from 'AppConfig';

const {
  domain,
  apiPath,
  usersPath,
  eventPath,
  listPath,
  deleteEventPaths,
  anonymousPath,
  bookmarkPath,
  dynamoPath,
  deleteEvent,
} = AppConfig.dynamoApi;

const API_URL = `${domain}/${apiPath}`;
const DATA_CAPTURE_URL = `${API_URL}/${usersPath}/${eventPath}`;
const COURSES_LIST = `${API_URL}/${usersPath}/${eventPath}/${listPath}`;
const DELETE_EVENT_PATH = `${API_URL}/${usersPath}/${eventPath}/${deleteEventPaths}`;
const ANONYMOUS_USER_REGISTRATION = `${API_URL}/${usersPath}/${anonymousPath}`;
const BOOKMARK_UPDATE = `${API_URL}/${bookmarkPath}`;
const BOOKMARK_DElETE= `${API_URL}/${usersPath}/${deleteEvent}`;



export {
  API_URL,
  DATA_CAPTURE_URL,
  COURSES_LIST,
  DELETE_EVENT_PATH,
  ANONYMOUS_USER_REGISTRATION,
  BOOKMARK_UPDATE,
  BOOKMARK_DElETE
};

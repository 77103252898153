import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import {
  fieldPropTypes,
  formValuesPropTypes,
  inlineErrorsPropTypes,
  touchedPropTypes,
} from 'components/shared/form/formPropTypes';
import { InlineError } from 'components/shared/form/InlineError';
import { useWebsiteConfigObject } from 'websiteConfig/useWebsiteConfig';
import './DefaultDropdown.scss';

const DefaultDropdown = ({
  field,
  fieldType,
  formValues,
  setFormValues,
  inlineErrors,
  touchedFields,
  setTouchedFields,
  className
}) => {

  const { anonymousSpecialityDropdownValues } = useWebsiteConfigObject();
  const dropdownRef = useRef(null);


  let specialities = [];

  if (
    anonymousSpecialityDropdownValues !== undefined &&
    anonymousSpecialityDropdownValues
  ) {
    specialities = anonymousSpecialityDropdownValues.split(' | ');
  }

  if (specialities.length === 0) {
    return '';
  }
  const { label, id, name, initialValue, typeParams, mandatory, size = 100 } = field;
  const {
    type,
    // stuff that html likes. Used for stuff like remembering password and username ect
    autoComplete,
    placeholder,
    readOnly,
  } = typeParams;



  const value = formValues[id];
  const touched = touchedFields[id];
  const inlineError = inlineErrors[id];
  const viewableError = InlineError && touched;

  const onChange = (e) => {
    const newValue = e.target.value;
    setFormValues((prevState) => ({
      ...prevState,
      [id]: newValue,
    }));
  };

  const setTouched = () =>
    setTouchedFields((prevState) => ({
      ...prevState,
      [id]: true,
    }));

  const fieldBaseClassName = 'form-field';
  const fieldClassNames = [
    fieldBaseClassName,
    `${fieldBaseClassName}-${fieldType}`,
    `${fieldBaseClassName}--${size}`,
    className
  
  ];

  if (readOnly) {
    fieldClassNames.push(`${fieldBaseClassName}-${fieldType}-read-only`);
  }

  if(type === "profile") {
    fieldClassNames.push(`${fieldBaseClassName}-${fieldType}-profile`);
  }

  if(type === "anonymous") {
    fieldClassNames.push(`${fieldBaseClassName}-${fieldType}-anonymous`);
  }
  const svgOnClick = () => {
    dropdownRef.current.click();
  }
  const requiredInput = (viewableError && inlineError && className) ? 'required-input' : '';

  return (
    <div className={fieldClassNames.join(' ')}>
      <div className='field-meta-data'>
        {label && (
          <label htmlFor={id}>
            {label}
            {mandatory && <span className='mandatory-astrix'>*</span>}
          </label>
        )}
        { !className && viewableError && inlineError && <InlineError inlineErrorText={inlineError} />}
      </div>
      <div className='default-dropdown-select'>
        <select
          id={id}
          name={name}
          className={`default-dropdown ${requiredInput}`}
          onChange={onChange}
          required={mandatory}
          onBlur={setTouched}
          ref={dropdownRef}
        >
          <option value=''>{specialities[0]}</option>
          {specialities &&
            specialities.map((item, i) => {
              if (i != 0 && item != "") {
                return (
                  <option key={item} value={item} selected={item === initialValue ? true : false}>
                    {item}
                  </option>
                );
              }
            })}
        </select>
        {
          className &&viewableError && inlineError && (
            <InlineError
              inlineErrorText={inlineError}
            />
          )
          }
      </div>
    </div>
  );
};

export { DefaultDropdown };
